import styled from '@emotion/styled';
import _ from 'lodash';
import { NextPage } from 'next';
import { splitRote } from 'src/utils/helper';
import { NextRouter, useRouter } from 'next/router';
import { IoChevronForwardCircle } from 'react-icons/io5';

type Props = {
  fontColor?: string;
  bgColor?: string;
  data?: any;
  link?: string;
  tabs?: boolean;
  setDataItem?: any;
  setActive?: any;
  dataItem?: any;
  active?: any;
  className?: string;
  showReadmore?: boolean;
  style?: string;
};
const SectionHeader: NextPage<Props> = ({ fontColor = '', bgColor = '', data, link, tabs = false, setDataItem, setActive, active, className = '', showReadmore = true, style = '' }) => {
  const router: NextRouter = useRouter();
  if (_.isEmpty(data)) return null;

  return (
    <>
      <SectionWrapper fontColor={fontColor} bgColor={bgColor}>
        {style === '' && (
          <>
            {tabs && data ? (
              <div className='heading --tab'>
                <div className='heading-active'>
                  <ul>
                    {_.map(data, (item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setDataItem(data[index]);
                          setActive({ key: index, status: true });
                        }}
                      >
                        <p className={`${active.status && active.key === index ? 'current' : ''}`}>{item?.title}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div className={`heading ${className}`}>
                <div className='heading-active'>
                  {(link === '#' || link === '') ? <p className='text-default'>{data || 'ข่าวล่าสุด'}</p> : <a href={`${splitRote(router)}${link || '#'}`}>
                    <p className=''>{data || 'ข่าวล่าสุด'}</p>
                  </a>}

                </div>
                {showReadmore && (
                  <ul className='heading-click'>
                    <li>
                      {(link === '#' || link === '') ? '' : <a href={`${splitRote(router)}${link || '#'}`}>
                        <p>
                          ดูทั้งหมด <IoChevronForwardCircle size={20} />
                        </p>
                      </a>}

                    </li>
                  </ul>
                )}
              </div>
            )}
          </>
        )}
        {style === 'default' && (
          <div className={`heading ${className} default`}>
            <div className='heading-active'>
              {(link === '#' || link === '') ? <p className='text-default'>{data || 'ข่าวล่าสุด'}</p> : <a href={`${splitRote(router)}${link || '#'}`}>
                <p className=''>{data || 'ข่าวล่าสุด'}</p>
              </a>}
            </div>
          </div>
        )}
        {style === 'category' && (
          <div className={`heading-category ${className} `}>
            <div className='heading-active'>
              {(link === '#' || link === '') ? <p className='text-default'>{data || 'ข่าวล่าสุด'}</p> : <a href={`${splitRote(router)}${link || '#'}`}>
                <p className=''>{data || 'ข่าวล่าสุด'}</p>
              </a>}
            </div>
          </div>
        )}
      </SectionWrapper>
    </>
  );
};

export default SectionHeader;

const SectionWrapper = styled.div<{ fontColor: string; bgColor: string }>`
  .heading {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.ntv-border-left {
      .heading-active {
        p {
          font-size: 34px;
          padding-left: 10px;
          line-height: 1;
          &::after {
            width: 4px;
            height: 100%;
            bottom: -15px;
          }
        }
      }
    }

    &.section--tag {
      background: var(--primary-color-new);
      .heading-active {
        p {
          color: #fff;
          margin: 0 0 0 10px;
          padding: 5px 0;
          &::after {
            background: none;
            height: unset;
          }
        }
      }
    }

    &.--tab {
      p {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 5px;
        color: #979797;
        padding-bottom: 5px;
        @media (max-width: 1024px) {
          font-size: 20px;
        }
        &.current {
          position: relative;
          color: #000;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 4px;
            width: 100%;
            background: linear-gradient(95deg, rgba(107, 163, 210, 1) 0%, rgba(67, 119, 202, 1) 30%, rgba(25, 69, 136, 1) 60%, rgba(30, 50, 86, 1) 90%);
          }
        }
      }
      /* flex-direction: row; */
      ul {
        display: flex;
        margin: 0 -10px;
        li {
          padding: 0 10px;
        }
      }
    }
    &.text-center {
      justify-content: center;
      background: linear-gradient(94.2deg, #4377ca 6.84%, #1e3256 100%);
      padding: 5px 0 10px 0;
    }
    &.white-color {
      .heading-active,
      .heading-click {
        a {
          color: #fff;
          p {
            color: #fff;
          }
        }
      }
    }

    &.contnet {
      position: relative;
      background: #fff;
      .heading-active {
        background-color: #fff;
        padding: 8px 16px 8px 0;
        a {
          color: #000;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    &.default {
      border-bottom: unset !important;
      .heading-active {
        padding: 0;
        background-color: transparent;
        a {
          font-size: 28px;
          font-weight: 500;
          color: #000 !important;
          @media (max-width: 690px) {
            font-size: 18px;
            color: #000 !important;
          }
        }
      }
    }

    &.center{
      justify-content: center;
      .heading-active {
        margin-bottom: 15px !important;
      }
      a {
        p {
          font-size: 32px !important;
          @media (max-width: 690px) {
            font-size: 20px !important;
          }
        }
      }
    }
    &.sub-menu {
      background: transparent;
      border-bottom: 1px solid #aaaaaa;
      .heading-click {
        li {
          a {
            p {
              color: ${props => props.fontColor || '#aaaaaa'};
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
      }
    }
    .heading-active {
      position: relative;
      a {
        font-size: 24px;
        font-weight: 500;
        color: #000;

        @media (max-width: 1024px) {
          font-size: 20px;
        }
        p {
          font-size: 24px;
          font-weight: 500;
          color: #000;
          margin-bottom: 5px;
          /* &.current {
            position: relative; */
          &::after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            height: 4px;
            width: 100%;
            background: var(--border-gradient);
            transform: matrix(1, 0, 0, 1, 0, 0) translate(0, -50%);
            /* background: linear-gradient(95deg, rgba(107, 163, 210, 1) 0%, rgba(67, 119, 202, 1) 30%, rgba(25, 69, 136, 1) 60%, rgba(30, 50, 86, 1) 90%); */
          }
          /* } */
        }
      }
      p.text-default {
        font-size: 24px;
        font-weight: 500;
        color: #000;
        margin-bottom: 5px;
        /* &.current {
          position: relative; */
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          width: 100%;
          background: var(--border-gradient);
          transform: matrix(1, 0, 0, 1, 0, 0) translate(0, -50%);
          /* background: linear-gradient(95deg, rgba(107, 163, 210, 1) 0%, rgba(67, 119, 202, 1) 30%, rgba(25, 69, 136, 1) 60%, rgba(30, 50, 86, 1) 90%); */
        }
        /* } */
      }
    }
    .heading-bar {
      .heading-bar--title {
        display: flex;
        align-items: center;
        color: #fff;
        svg {
          margin: 0px 8px 0;
        }
      }
    }
    .heading-click {
      &--absolute {
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 0px 6px 1px #00000031;
        z-index: 1;
        padding: 10px;
        right: 0;
        top: 40px;
        .current {
          color: var(--primary-color);
          font-weight: 600;
        }
        li {
          padding: 8px 0;
        }
      }
      &.desktop {
        display: flex;
        gap: 20px;
        margin-right: 20px;
      }
      li {
        cursor: pointer;
        p {
          color: #545454;
          vertical-align: middle;
          display: flex;
          align-items: center;
          font-size: 14px;
          svg {
            margin: 0px 0 0 8px;
          }
        }
        .current {
          color: var(--text-color);
          background: var(--primary-gradient);
          background-blend-mode: overlay, normal;
          border-radius: 15px;
          padding: 2px 14px;
        }
      }
    }
  }

  .heading-category {
    position: relative;
    margin: 30px auto;
    /* width: 500px; */
    width: max-content;
    max-width: 100%;
    padding: 0 30px;

    &::before,
    &::after {
      position: absolute;
      width: 200px;
      height: 4px;
      top: 50%;
      @media (max-width: 690px) {
        width: 100px;
        height: 3px;
      }
    }
    &::before {
      content: '';
      left: 100%;
      background: var(--primary-color-new);
      transform: matrix(1, 0, 0, 1, 0, 0) translate(0, -50%);
      /* @media (max-width: 690px) {
        left: -30px;
      } */
    }
    &::after {
      content: '';
      right: 100%;
      background: var(--primary-color-new);
      transform: matrix(-1, 0, 0, 1, 0, 0) translate(0, -50%);
      /* @media (max-width: 690px) {
        right: -30px;
      } */
    }
    a {
      color: var(--primary-color-new);
      p {
        font-weight: 700;
        font-size: 48px;
        text-align: center;
        @media (max-width: 690px) {
          font-size: 32px;
        }
      }
    }
  }
`;
